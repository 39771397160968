<template>
<div>

  <div class="top-navbar">
    <i style="display: inline-block; font-size: 1.5rem;  margin-left: 15px; color: white;" class="fa-solid fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"></i>
    <h1 style="display: inline-block; margin: 0; padding: 0; line-height: 50px; font-size: 1.25rem; width: fit-content; margin-left: 15px; color:white; white-space: nowrap;"> {{ this.$store.state.appTitle }}</h1>
  </div>

  <div class="content-body">
    <router-view></router-view>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style="background-color: #1e385e;">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel" style="color: white; font-weight: bold;">Garaža Firule Split</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <ul style="list-style-type: none;" class="side-menu">
        <li style="color: white; font-weight: normal; padding: 10px;" v-bind:class="{ active: this.currentActiveView == '/rezervacije' }" @click="this.setView('/rezervacije')">
          Rezervacije
        </li>
        <li style="color: white; font-weight: normal; padding: 10px;" v-bind:class="{ active: this.currentActiveView == '/energija' }" @click="this.setView('/energija')">
          Energija
        </li>
      </ul>
    </div>
  </div>

  <div class="datetime-float-box" v-show="false">
    <span>04.07.2022</span>
    <span>19:21</span>
  </div>
</div>
</template>

<script>

Array.prototype.remove = function(key)
{
    for(var i = 0; i < this.length; ++i)
    {
        if(this[i] == key)
        {
            this.splice(i, 1);
            return;
        }
    }
}


export default {
  data() { return {
    currentActiveView: undefined,
    timers: [],
    sideMenu: undefined,
  }},
  methods: {
    setView(fullPath) {
      this.sideMenu.hide()
      this.$router.push(fullPath)
    },

    triggerLoading() {
      this.loadLiveData();
    },
    async loadLiveData() {
      if(!this.timers.includes('WaitTillNextRefresh_loadLiveData')) {
        await this.$store.dispatch('getLiveData').catch((err) => {console.log(err)}).finally(() => { this.startTimer('WaitTillNextRefresh_loadLiveData', 12000)});
      }
    },
    startTimer(timerName, interval = 1000) {
        if(!this.timers.includes(timerName)) {
          this.timers.push(timerName);
          this.timers[timerName] = setTimeout(() => {
            clearTimeout(this.timers[timerName]);
            this.timers.remove(timerName);
          }, interval);
        } else {
            clearTimeout(this.timers[timerName]);
            this.timers[timerName] = setTimeout(() => {
            clearTimeout(this.timers[timerName]);
            this.timers.remove(timerName);
          }, interval);
        }
    }
  },
  mounted() {
    this.sideMenu = new window.bootstrap.Offcanvas('#offcanvasExample')
    this.setView('/rezervacije');
  },  
  watch: {
    'timers': {
      handler() {
        this.triggerLoading();
      },
      immediate: true,
      deep: true
    },
    '$route' (to, from) {
      this.currentActiveView = to['fullPath'] || undefined;
    }
  }
}

</script>

<style lang="less">
#app {
    font-family: Helvetica, Arial, sans-serif;
    font-family: 'Barlow', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.top-navbar {
  width: 100%;
  height: 50px;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  background: #216e93;
  white-space: nowrap;
}

.content-body {
  position: absolute;
  top: 50px;
  left: 0px;
  min-width: 100vw;
  min-height: calc(100vh - 50px);
  height: auto;
  background: linear-gradient(to bottom left,#216e93,#191c51);
}


.side-menu {

  padding-left: 0;

  & > li {

  &.active  {
    color: black !important;
    background: #83e8a2 !important;
  }

}
}

.offcanvas {
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
  width: unset !important;
}

.datetime-float-box {
   position: absolute;
   top: 60px;
   left: 10px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   span {
    width: 100%;
    text-align: center;
    padding: 10px;
    background: white;
    margin-bottom: 5px;
    height: 30px;
    border-radius: 15px;
   }
}

</style>
