import { createRouter, createWebHashHistory } from 'vue-router'
import energija from '../views/energija.vue'
import energijagrafi from '../views/energijagrafi.vue'
import rezervacije from '../views/rezervacije.vue'
import rezervacija from '../views/rezervacija.vue'

const routes = [{
    path: '/energija',
    name: 'energija',
    component: energija
}, {
    path: '/energija-diagram',
    name: 'energija-diagram',
    component: energijagrafi
}, {
    path: '/rezervacije',
    name: 'rezervacije',
    component: rezervacije
}, {
    path: '/rezervacija',
    name: 'rezervacija',
    component: rezervacija
}]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router