<template>
    <div class="content-page-rezervacije">

        <div style="position: fixed; top: 50%; left: 50%; width: max-content; transform: translate(-50%, -50%); background:#216e93; padding: 10px; box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);" v-show="this.isLoadingIndicatorVisible">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;" class="col">
          <div style="color: #83e8a2 !important;" class="spinner-border text-secondary" role="status"></div>
          <p style="margin: 0; padding-left: 15px; color: #83e8a2 !important;">{{ LoadingIndicatorText }}</p>
        </div>
    </div>

        <i class="details-icon fa-solid fa-list" v-bind:class="{visible: this.rowSelected != null }" @click="this.reservationDetailModalVisible = !this.reservationDetailModalVisible"></i>
        
        <i class="add-reservation-icon fa-solid" style="display:none;" v-bind:class="{'fa-plus': this.rowSelected == null,  'fa-pen': this.rowSelected != null}" @click="this.$router.push({ path: '/rezervacija', query: { id: this.rowSelected } })"></i>
        
        <div id="reservation-details-modal" v-bind:class="{visible: this.reservationDetailModalVisible && this.rowSelected}">
             <table class="table reservation-details-modal-table">
                <tr>
                    <th>Name</th>
                    <td>{{ selectedReservation['client_name']}}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{{ selectedReservation['client_email']}}</td>
                </tr>
                <tr>
                    <th>Mobile</th>
                    <td>{{ selectedReservation['client_telephone']}}</td>
                </tr>
                <tr>
                    <th>Message</th>
                    <td>{{ selectedReservation['client_message']}}</td>
                </tr>
              </table> 
        </div>

        <div class="reservation-list-container">
            <div class="sort-selection-group-container">
                <div class="sort-selection-group">
                    <div class="sort-selection-button" v-bind:class="{active: this.reservationListSort == 'created'}" @click="this.reservationListSort = 'created'"><span>Created</span></div>
                    <div class="sort-selection-button" v-bind:class="{active: this.reservationListSort == 'arrival'}" @click="this.reservationListSort = 'arrival'"><span>Arrival</span></div>
                    <div class="sort-selection-button" v-bind:class="{active: this.reservationListSort == 'arrival_today'}" @click="this.reservationListSort = 'arrival_today'"><span>Arrival Today</span></div>        
                    <div class="sort-selection-button" v-bind:class="{active: this.reservationListSort == 'in_garage'}" @click="this.reservationListSort = 'in_garage'"><span>In Garage</span></div>
                </div>
            </div>

            <table class="table table-reservation">
                <thead>
                    <tr>
                        <th style="display:none;">Number</th>
                        <th v-bind:class="{hidden: this.orderNumberTableHidden}">Number</th>
                        <th>Created</th>
                        <th>Client</th>
                        <th>Plate</th>
                        <th>Arrival</th>
                        <th>Departure</th>
                        <th>Price</th>
                        <th style="display:none;">Source</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="reservation in  sortedListOfReservation" :key="reservation['id']" @click="onRowSelect" v-bind:class="{selected: this.rowSelected == reservation['id'], headerRow: reservation['id'] == -1}">
                        <th style="display:none;">{{ reservation['id'] }}</th>
                        <th v-bind:class="{hidden: this.orderNumberTableHidden}">{{ reservation['orderNumber'] }}</th>
                        <th>{{ getTimeFormatedStringFromTimestamp(reservation['timestamp_created']*1000) }}</th>
                        <th>{{ reservation['client_name'] }}</th>
                        <th>{{ uppercasePlateNumber(reservation['client_registration_plate']) }}</th>
                        <th>{{ getDateTime(reservation['arrival_datetime'], reservation['arrival_timestamp']) }}</th>
                        <th>{{ getDateTime(reservation['departure_datetime'], reservation['departure_timestamp']) }}</th>
                        <th>{{ getEuroPrice(reservation['estimated_price']) }} €</th>
                        <th style="display:none;">{{ reservation['source'] }}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
  data() {
    return {
        isLoadingIndicatorVisible: false,
        LoadingIndicatorText: 'Učitavanje',
        listOfReservation: [],
        sortedListOfReservation: [],
        rowSelected: null,
        selectedReservation: {
            client_name: null,
            client_email: null,
            client_telephone: null,
            client_message: null,
        },
        reservationDetailModalVisible: false,
        reservationListSort: 'created',     //created, incoming
        orderNumberTableHidden: true,
    }
  },
  mounted() {
    this.$store.state.appTitle = 'mFirule/Rezervacije'
    this.LoadingIndicatorText = 'Učitavanje'
    this.loadReservationList()
  },
  computed: {
  },
  methods: {
    getDateFromWebString(datetime, timestamp = null) {
        //args format is "10-08-2022 20:00"

        if (timestamp != null && timestamp > 0) {
            let ts = Number(timestamp)*1000;
            return new Date(ts);
        }

        try {
            let dt = String(datetime).split(' ');
            if(dt.length == 2) {
                let d = dt[0].split('-')
                let t = dt[1].split(':')
                if (d.length == 3 && t.length == 2) {
                    return new Date(d[2], d[1] - 1, d[0], t[0], t[1])
                }
            }
        } catch {}
        
        return null
    },
    loadReservationList() {
        this.isLoadingIndicatorVisible = true;
        this.LoadingIndicatorText = 'Učitavanje';
        this.$store.dispatch('getReservationListData').catch((err) => {console.log(err)}).then((res) => { 
            this.listOfReservation = res.data 
            this.sortListOfReservation()
        });
    },
    uppercasePlateNumber(str) {
        if(str) return String(str).toUpperCase()
        return null
    },
    getTimeFormatedStringFromTimestamp(ts) {
        let time = new Date(ts)
        if (!isNaN(time.getTime()))
        return `${('0'+ time.getDate()).slice(-2)}.${('0'+ (time.getMonth() + 1)).slice(-2)}.${time.getFullYear()} ${('0'+time.getHours()).slice(-2)}:${('0'+time.getMinutes()).slice(-2)}`;
        return null
    },
    getDateTime(dt, ts) {
        if (ts > 0) return this.getTimeFormatedStringFromTimestamp(ts*1000);
        return dt
    },
    getEuroPrice(strPrice) {
        //string is xxx kn / xxx €
        //new format is only number
        let a = String(strPrice);
        let b = a.split('/');
        let e = 0;
        if(b.length == 2) {
            e = b[1].replace(/\D/g, '');
        } else {
            e = strPrice;
        }
        let res = Number(e);
        if (res == NaN) res = 0;
        return res;
    },
    sortListOfReservation() {
        this.LoadingIndicatorText = 'Sortiranje';
        this.isLoadingIndicatorVisible = true;
        
        if(this.reservationListSort == 'created') {

            let orginalListOfReservation = [...this.listOfReservation];
            //sort list by created timestamp
            orginalListOfReservation.sort((a, b) => { return b['timestamp_created'] - a['timestamp_created']}); 

            let reservationByDay = []
            let amountsByDay = []

            let createdList = []

            let i, date, prettyDate, numbersOfReservationInCurrentDay, currentDay, currentDayPrice;

            let position = 0;

            for(i = 0; i < orginalListOfReservation.length; i++) {

                date = new Date(orginalListOfReservation[i]['timestamp_created']*1000);
                prettyDate = `${('0'+date.getDate()).slice(-2)}.${('0'+(date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`

                if (!(prettyDate in reservationByDay)) {
                    reservationByDay[prettyDate] = []
                    position++;
                }
                
                reservationByDay[prettyDate].push(orginalListOfReservation[i])
                reservationByDay[prettyDate]['position'] = position

                if (!(prettyDate in amountsByDay)) {
                    amountsByDay[prettyDate] = 0
                }
                
                amountsByDay[prettyDate] = amountsByDay[prettyDate] + this.getEuroPrice(orginalListOfReservation[i]['estimated_price']);


                /*

                if(createdList.find(res => res['orderNumber'] == prettyDate) == undefined) {
                    oneDayListOfReservation = []
                    if(currentDay) {    //update price
                        let row = createdList.find(res => res['orderNumber'] == currentDay)
                        row['estimated_price'] = `${currentDayPrice} €`;
                    }
                    currentDay = prettyDate;
                    currentDayPrice = 0;
                    oneDayListOfReservation.push({'id': -1, 'orderNumber' : prettyDate, 'estimated_price' : null})
                    numbersOfReservationInCurrentDay = 1;
                } else {
                    numbersOfReservationInCurrentDay++;
                }

                currentDayPrice = currentDayPrice + this.getEuroPrice(orginalListOfReservation[i]['estimated_price']);
                orginalListOfReservation[i]['orderNumber'] = numbersOfReservationInCurrentDay;
                oneDayListOfReservation.push(orginalListOfReservation[i]);

                createdList.push(orginalListOfReservation[i]);
                */
                    
            }

            let keys = Object.keys(reservationByDay);

            //created entryes for days sort ascending
            keys.forEach(element => {
                (reservationByDay[element]).sort((a, b) => { return b['timestamp_created'] - a['timestamp_created']}); 

                let number = 1;

                (reservationByDay[element]).forEach(entry => { 
                    entry['orderNumber'] = number;
                    number++;
                });
            });

            for(let k = 1; k <= keys.length; k++) {
                for(let j = 0; j < keys.length; j++) {
                    if(k == reservationByDay[keys[j]]['position']) {
                        let header = []
                        header.push({
                            'id': -1, 
                            'orderNumber' : keys[j], 
                            'estimated_price' : amountsByDay[keys[j]]
                        });
                        createdList = [...createdList, ...header, ...reservationByDay[keys[j]]]
                        break
                    }
                }
            }
       
            this.sortedListOfReservation = [...createdList];
            this.orderNumberTableHidden = false;
        }
        if(this.reservationListSort == 'arrival') {
            this.orderNumberTableHidden = true;
            this.sortedListOfReservation = [...this.listOfReservation];
            try  {
                let i, ts
                for (i=0; i<this.sortedListOfReservation.length; i++) {
                        ts = this.getDateFromWebString(this.sortedListOfReservation[i]['arrival_datetime'], this.sortedListOfReservation[i]['arrival_timestamp'])
                        if (ts && typeof ts.getTime == 'function') {
                            this.sortedListOfReservation[i]['arrival_datetime_timestamp'] = ts.getTime()
                        } else {
                            this.sortedListOfReservation[i]['arrival_datetime_timestamp'] = 0
                        }
                }

                let currentTs = new Date();   
                let start = new Date(currentTs.getFullYear(), currentTs.getMonth(), currentTs.getDate(), 0, 0, 0).getTime();    //today and forward
                this.sortedListOfReservation = this.sortedListOfReservation.filter(res => res['arrival_datetime_timestamp'] > start); 

                this.sortedListOfReservation.sort((a, b) => { return a['arrival_datetime_timestamp'] - b['arrival_datetime_timestamp']}); 
                
            } catch {

            }
        }
        if(this.reservationListSort == 'arrival_today') {
            this.orderNumberTableHidden = true;
            this.sortedListOfReservation = [...this.listOfReservation];
            try  {
                let i, ts
                for (i=0; i<this.sortedListOfReservation.length; i++) {
                        ts = this.getDateFromWebString(this.sortedListOfReservation[i]['arrival_datetime'], this.sortedListOfReservation[i]['arrival_timestamp'])
                        if (ts && typeof ts.getTime == 'function') {
                            this.sortedListOfReservation[i]['arrival_datetime_timestamp'] = ts.getTime()
                        } else {
                            this.sortedListOfReservation[i]['arrival_datetime_timestamp'] = 0
                        }
                }

                let currentTs = new Date();   
                let start = new Date(currentTs.getFullYear(), currentTs.getMonth(), currentTs.getDate(), 0, 0, 0).getTime();    //today and forward
                let end = new Date(start + 24*60*60*1000).getTime();
                this.sortedListOfReservation = this.sortedListOfReservation.filter(res =>  start <= res['arrival_datetime_timestamp'] && res['arrival_datetime_timestamp'] <= end); 

                this.sortedListOfReservation.sort((a, b) => { return a['arrival_datetime_timestamp'] - b['arrival_datetime_timestamp']}); 
                
            } catch {

            }

        }
        if(this.reservationListSort == 'in_garage') {
            this.orderNumberTableHidden = true;
            this.sortedListOfReservation = [...this.listOfReservation];
            try  {
                let i, ts
                for (i=0; i<this.sortedListOfReservation.length; i++) {
                        ts = this.getDateFromWebString(this.sortedListOfReservation[i]['arrival_datetime'], this.sortedListOfReservation[i]['arrival_timestamp'])
                        if (ts && typeof ts.getTime == 'function') {
                            this.sortedListOfReservation[i]['arrival_datetime_timestamp'] = ts.getTime()
                        } else {
                            this.sortedListOfReservation[i]['arrival_datetime_timestamp'] = 0
                        }
                        ts = this.getDateFromWebString(this.sortedListOfReservation[i]['departure_datetime'], this.sortedListOfReservation[i]['departure_timestamp'])
                        if (ts && typeof ts.getTime == 'function') {
                            this.sortedListOfReservation[i]['departure_datetime_timestamp'] = ts.getTime()
                        } else {
                            this.sortedListOfReservation[i]['departure_datetime_timestamp'] = 0
                        }
                }

                let currentTs = new Date().getTime();   

                this.sortedListOfReservation = this.sortedListOfReservation.filter(res =>  res['arrival_datetime_timestamp'] <= currentTs && currentTs <= res['departure_datetime_timestamp']); 

                this.sortedListOfReservation.sort((a, b) => { return a['arrival_datetime_timestamp'] - b['arrival_datetime_timestamp']}); 
                
            } catch {

            }

        }
        this.isLoadingIndicatorVisible = false;

    },
    onRowSelect(e) {
        try {
            let row = e.target.parentElement.firstChild.innerText   //
            if(this.rowSelected != null && this.rowSelected == row || row == -1) { 
                this.rowSelected = null
                this.selectedReservation['client_name'] = null
                this.selectedReservation['client_email'] = null
                this.selectedReservation['client_telephone'] = null
                this.selectedReservation['client_message'] = null
            } else {
                this.rowSelected = row
                let selectedReservation = this.sortedListOfReservation.find(res => res['id'] == row)
                if(selectedReservation) {
                    this.selectedReservation['client_name'] = selectedReservation['client_name']
                    this.selectedReservation['client_email'] = selectedReservation['client_email']
                    this.selectedReservation['client_telephone'] = selectedReservation['client_telephone']
                    this.selectedReservation['client_message'] = selectedReservation['client_message']
                } else {
                    this.selectedReservation['client_name'] = null
                    this.selectedReservation['client_email'] = null
                    this.selectedReservation['client_telephone'] = null
                    this.selectedReservation['client_message'] = null
                }
            }
        } catch {

        }
        
    }
  }, 
  watch: {
    'reservationListSort': function(e) {
        this.sortListOfReservation()
    }
  }
}

</script>

<style lang="less">

.content-page-rezervacije {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reservation-list-container {
    padding: 10px;
    background: #1e385e;
    width: 100vw;
    overflow-x: auto;

    .sort-selection-group {
        
        width: fit-content;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
        margin: 25px;
        padding: 10px;
        background-color: #1e385e44;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        border-radius: 30px;
        align-items: center;

        p {
            height: 30px;
            margin: 5px;
        }

        .sort-selection-button {
            transition: all .2s ease-in-out;
            height: 30px;
            padding-left: 10px;
            padding-right: 10px;
            margin: 5px;
            background-color: #216e93;
            
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            span {
            color: white;
            white-space: nowrap;
            font-weight: bold;  
            font-size: 0.9rem;
            }

            &.active {

                span {
                    color: black;
                }
                
                background-color: #83e8a2;
            }
        }
    }

    .table-reservation {
        tr.selected {
            background: black;
        }

        tr.headerRow {
            border-bottom: 2px solid #ffa700;
            th {
                color: #ffa700;
                font-weight: bold;
            }
        }

        th, td {
            white-space: nowrap;
            color: white;
            font-weight: normal;
            vertical-align: middle;
            width: min-content;

            &.hidden {
                display: none;
            }
        }
    }
}






.details-icon {
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 48.8px;
    height: 48.8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
    font-size: 1.8rem;
    color: #8EE8A2;
    padding: 10px;
    margin: 10px;
    border-radius: 50%;
    background-color: #216e93;
    text-align: center;
    transform: translateX(100px);
    transition: all .3s ease-in-out;

    &.visible {
        transform: unset;
    }
}

.reservation-details-modal-table {
    overflow-x: hidden;

    th, td {
        color: white;
        font-weight: normal;
    }
    
    tr {
        border-top: none !important;
    }
}

#reservation-details-modal {
    max-width: 350px;
    width: 80vw;
    overflow-x: auto;
    background: #216e93;
    padding: 20px;
    height: fit-content;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none; 
    
    &.visible {
        display: block;
    }
}

.add-reservation-icon {
    position: fixed;
    z-index: 1002;
    top: 8px;
    right: 12px;
    width: 35px;
    height: 35px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
    font-size: 1.1rem;
    color: #8EE8A2;
    padding: 10px;
    border-radius: 50%;
    background-color: #216e93;
    text-align: center;
}

</style>