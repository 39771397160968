<template>
    <div class="content-page diagram-page">

        <div class="chart-selection-group">
            <div class="chart-selection-button" v-bind:class="{ active: this.selectedChart == 0 }" @click="this.selectedChart = 0"><span>12h</span></div>
            <div class="chart-selection-button" v-bind:class="{ active: this.selectedChart == 1 }" @click="this.selectedChart = 1"><span>24h</span></div>
            <div class="chart-selection-button" v-bind:class="{ active: this.selectedChart == 2 }"><span>Month</span></div>
            <div class="chart-selection-button" v-bind:class="{ active: this.selectedChart == 3 }"><span>Year</span></div>        
        </div>

        <div class="dropdown" style="margin-top: 25px;">
            <button style="box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%); background: #1f4d76 !important; border-radius: 15px; font-weight: bold; border-color: transparent !important;" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ nameOfCurrentCounter }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" @click="this.selectedCounter=counters[0]['value']">{{ counters[0]['name'] }}</a></li>
                <li><a class="dropdown-item" @click="this.selectedCounter=counters[1]['value']">{{ counters[1]['name'] }}</a></li>
                <li><a class="dropdown-item" @click="this.selectedCounter=counters[2]['value']">{{ counters[2]['name'] }}</a></li>
            </ul>
        </div>

        <div id="chart-container"></div>

        <div style="position: fixed; top: 50%; left: 50%; width: max-content; transform: translate(-50%, -50%); background:#216e93; padding: 10px; box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);" v-show="this.isLoadingIndicatorVisible">
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;" class="col">
          <div style="color: #83e8a2 !important;" class="spinner-border text-secondary" role="status"></div>
          <p style="margin: 0; padding-left: 15px; color: #83e8a2 !important;">Učitavanje</p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
       counters: [
        {value: 1, name: 'Punionica čistač'},
        {value: 2, name: 'Punionica TUC 1'},
        {value: 3, name: 'Punionica TUC 2'},
       ],
       selectedCounter: 0,
       selectedChart: 0,        //0 day, 1 month, 2 year
       isLoadingIndicatorVisible: false,
    }
  },
  mounted() {
    if(this.$route.query.counter) {
        this.selectedCounter = this.$route.query.counter;
    }
    this.$store.state.appTitle = 'mFirule/Energija/Diagrami'
    this.selectedChart = 0
  },
  computed: {
    nameOfCurrentCounter() {
       let sC = this.counters.find(c => c.value == this.selectedCounter);
       if(sC) return sC.name; 
       return '-';
    },

  },
  methods: {
    draw12hChart(data) {
        try {
            let powerSeries = [];

            if(data.data.length) {
                let json, ts, x, y;
                for(let i = 0; i < data.data.length; i++) {
                    json = JSON.parse(data.data[i]['stats']);
                    ts = parseInt(data.data[i]['timestamp'])
                    x = new Date(ts * 1000);
                    y = Math.round(10 * json['TotalActivePower'] * 10) / 10; //W
                    powerSeries.push({x: x, y: y});
                }
            } else return;

            let highcharts_options = {
                legend: {
                    itemStyle: {
                        color: '#83e8a2',
                        fontWeight: 'normal'
                    }
                },
                chart: {
                    backgroundColor: '#1e385e44',
                    type: 'spline'
                },
                title: {
                    text: '12 h',
                    style : {color: '#83e8a2'}
                },             
                yAxis : {
                    title: {
                        text: null
                    }, 
                    labels: {
                        style : {color: '#83e8a2'}
                    }
                },
                xAxis: {
                    type: 'datetime',
                    zoomType: 'x',
                    panning: true,
                    labels: {
                        style : {color: '#83e8a2'}
                    }
                },
                series: [{
                    name: 'El. snaga',
                    turboThreshold: 10000,
                    data: powerSeries,
                    tooltip: {
                        valueSuffix: ' W'
                    },
                }]
            }
            Highcharts.chart('chart-container', highcharts_options);  //draw chart

        } catch {

        }

        this.isLoadingIndicatorVisible = false;
    },
    draw24hChart(data) {
        try {
            let powerSeries = [];
            if(data.data.length) {
                let json, ts, x, y;
                for(let i = 0; i < data.data.length; i++) {
                    json = JSON.parse(data.data[i]['stats']);
                    ts = parseInt(data.data[i]['timestamp'])
                    x = new Date(ts * 1000);
                    y = Math.round(10 * json['TotalActivePower'] * 10) / 10; //W
                    powerSeries.push({x: x, y: y});
                }
            } else return;

            let highcharts_options = {
                legend: {
                    itemStyle: {
                        color: '#83e8a2',
                        fontWeight: 'normal'
                    }
                },
                chart: {
                    backgroundColor: '#1e385e44',
                    type: 'spline'
                },
                title: {
                    text: '24 h',
                    style : {color: '#83e8a2'}
                },
                yAxis : {
                    title: {
                        text: null
                    },
                    labels: {
                        style : {color: '#83e8a2'}
                    }
                },
                xAxis: {
                    type: 'datetime',
                    zoomType: 'x',
                    panning: true,
                    labels: {
                        style : {color: '#83e8a2'}
                    }
                },
                series: [{
                    name: 'El. snaga',
                    turboThreshold: 10000,
                    data: powerSeries,
                    tooltip: {
                        valueSuffix: ' W'
                    },
                }]
            }
            Highcharts.chart('chart-container', highcharts_options);  //draw chart
        } catch {

        }
        
        this.isLoadingIndicatorVisible = false;
    },
    loadChart() {
        this.isLoadingIndicatorVisible = true;
        let currentTimestamp = Math.round(new Date().getTime()/1000)
        //12 h 
        if(this.selectedChart == 0) this.$store.dispatch('getChartData', { counter:  this.selectedCounter, ts: currentTimestamp, interval: 43200}).catch((err) => {console.log(err); this.isLoadingIndicatorVisible = false;}).then((r) => {this.draw12hChart(r)});
        
        //24h
        if(this.selectedChart == 1) this.$store.dispatch('getChartData', { counter:  this.selectedCounter, ts: currentTimestamp, interval: 86400}).catch((err) => {console.log(err); this.isLoadingIndicatorVisible = false;}).then((r) => {this.draw24hChart(r)});
        
    }
  }, 
  watch: {
    'selectedCounter': function() {
        this.loadChart();
    },
    'selectedChart': function() {
        this.loadChart();
    },
  }
}

</script>

<style lang="less">

.content-page {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &.diagram-page {
        flex-direction: column;
    }
}

.chart-selection-group {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
    margin-top: 25px;
    width: fit-content;
    padding: 10px;
    background-color: #1e385e44;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 30px;

    .chart-selection-button {
        transition: all .2s ease-in-out;
        height: 30px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 5px;
        background-color: #216e93;
        
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          color: white;
          font-weight: bold;  
          font-size: 0.9rem;
        }

        &.active {

            span {
                color: black;
            }
            
            background-color: #83e8a2;
        }
    }
}

#chart-container {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
    margin-top: 25px;
    min-height: 1px;
    width: 80%;
    max-width: 750px;    
}

</style>