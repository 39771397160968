export default {

    getLiveData() {
        return new Promise((resolve, reject) => {
            let controller = new AbortController();
            let signal = controller.signal;

            fetch(this.state.API + 'live.php', {
                    method: 'GET',
                    signal,
                })
                .then(res => {
                    if (res.status === 200) {
                        res.text().then(val => {
                            let obj = JSON.parse(val);
                            this.state.liveData = obj;
                            resolve('Success');
                        })
                    }
                    if (res.status === 401) {
                        reject('Neovlašteni pristup.');
                    }
                    if (res.status === 500 || res.status === 404) {
                        reject('Greška na serveru.');
                    }
                })
                .catch(err => {
                    reject('Greška dohvačanja. ' + err);
                });

            setTimeout(() => { controller.abort(); }, 2000); //timeout 2 sec
        });
    },

    getChartData(sender, args) {
        return new Promise((resolve, reject) => {
            let controller = new AbortController();
            let signal = controller.signal;

            let queryString = '';

            if (typeof args == 'object') {
                for (let ar in args) {
                    if (queryString.length != 0) queryString = queryString + "&";
                    queryString = queryString + ar + "=" + args[ar];
                }
            }

            fetch(this.state.API + 'chart.php?' + queryString, {
                    method: 'GET',
                    signal,
                })
                .then(res => {
                    if (res.status === 200) {
                        res.text().then(val => {
                            let obj = JSON.parse(val);
                            resolve({ status: 'Success', data: obj });
                        })
                    }
                    if (res.status === 401) {
                        reject('Neovlašteni pristup.');
                    }
                    if (res.status === 500 || res.status === 404) {
                        reject('Greška na serveru.');
                    }
                })
                .catch(err => {
                    reject('Greška dohvačanja. ' + err);
                });

            setTimeout(() => { controller.abort(); }, 10000); //timeout 10 sec
        });
    },

    getReservationListData() {
        return new Promise((resolve, reject) => {
            let controller = new AbortController();
            let signal = controller.signal;

            fetch(this.state.API + 'reservation/list/', {
                    method: 'GET',
                    signal,
                })
                .then(res => {
                    if (res.status === 200) {
                        res.text().then(val => {
                            let obj = JSON.parse(val);
                            resolve({
                                status: 'Success',
                                data: obj
                            });
                        })
                    }
                    if (res.status === 401) {
                        reject('Neovlašteni pristup.');
                    }
                    if (res.status === 500 || res.status === 404) {
                        reject('Greška na serveru.');
                    }
                })
                .catch(err => {
                    reject('Greška dohvačanja. ' + err);
                });

            setTimeout(() => { controller.abort(); }, 5000); //timeout 5 sec
        });
    }
}