<template>
    <div class="content-page">
        <i class="close-reservation-icon fa-solid fa-xmark" @click="this.$router.push('/rezervacije')"></i>
        <form onsubmit="return false">
            <div class="form-group">
                <label for="input_source">Source</label>
                <select class="form-select" id="input_source">
                    <option selected value="WEB">WEB</option>
                    <option value="Whatsapp">Whatsapp</option>
                    <option value="Broker">Broker</option>
                </select>
            </div>
            <div class="form-group">
                <label for="input_client_name">Client Name</label>
                <input type="text" class="form-control" id="input_client_name" required>
            </div>
            <div class="form-group">
                <label for="input_client_mail">Client email</label>
                <input type="email" class="form-control" id="input_client_mail" required>
            </div>
            <div class="form-group">
                <label for="input_client_mobile">Client mobile</label>
                <input type="text" class="form-control" id="input_client_mobile" required>
            </div>
            <div class="form-group">
                <label for="input_client_plate">Registration plate</label>
                <input type="text" class="form-control" id="input_client_plate" required>
            </div>
            <div class="form-group">
                <label for="input_client_message">Client message</label>
                <textarea class="form-control" id="input_client_message"></textarea>
            </div>
            <div class="form-group">
                <label for="input_arrival_date">Arrival</label>
                <input type="date" class="form-control" pattern="\d{2}-\d{2}-\d{4}" id="input_arrival_date" required>
                <input type="time" value="10:00"  class="form-control" id="input_arrival_time" required>
            </div>
            <div class="form-group">
                <label for="input_departure_date">Departure</label>
                <input type="date" class="form-control" pattern="\d{2}-\d{2}-\d{4}" id="input_departure_date" required>
                <input type="time" value="10:00" class="form-control" id="input_departure_time" required>
            </div>
            <div class="form-group">
                <label for="input_estimated_price">Estimated price</label>
                <input type="text" class="form-control" readonly id="input_estimated_price" required>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-primary" disabled>Add</button>
            </div>
            
        </form>
    </div>
</template>

<script>

export default {
  data() {
    return {
        selectedId: null,
    }
  },
  mounted() {
    this.$store.state.appTitle = 'mFirule/Rezervacija'
  },
  computed: {
  },
  methods: {
    
  }, 
  watch: {
    'reservationListSort': function(e) {
        this.sortListOfReservation()
    }
  }
}

</script>

<style lang="less">

.content-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.close-reservation-icon
{
    position: fixed;
    z-index: 1002;
    top: 8px;
    right: 12px;
    width: 35px;
    height: 35px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
    font-size: 1.1rem;
    color: #8EE8A2;
    padding: 10px;
    border-radius: 50%;
    background-color: #216e93;
    text-align: center;
}

form {
    margin-top: 25px;
    .form-group {
        margin-bottom: 25px;
        margin-top: 15px;
        label {
            color: white;
            font-size: 1.2rem;
            font-weight: 500;
        }

        input, textarea, select {
            background: transparent !important;
            background-color: transparent !important;
            border-radius: unset !important;
            border: none !important;
            color: white !important;
            border-bottom: 1px solid #83e8a2 !important;
            
            width: 80vw;
            max-width: 750px;
        }

        button {
            width: 80vw;
            max-width: 750px;
        }
    }
}


</style>