<template>
    <div class="content-page">
            <div class="test">

                <div style="padding-bottom: 10px; border-bottom: 1px solid #83E8A2; text-align: center;">
                    <p>Punionica čistač</p>
                </div>
                <div style="display: flex; flex-wrap:nowrap;">
                    <div style="padding-right: 20px; border-right: 1px solid #83E8A2; flex: 50%;">
                        <div style="display: flex; flex-wrap: nowrap;">
                            <div style="margin: 10px; flex: 0 50%; text-align: center;">
                                <p>{{ Math.round(10*counter1.stats.Phase1Voltage/100)/10 }} V</p>
                            </div>
                            <div style="margin: 10px; flex: 0 50%; text-align: center;">
                                <p>{{ Math.round(10*counter1.stats.Phase1Current/1000)/10 }} A</p>
                            </div>
                        </div>
                        <div style="display: flex; flex-wrap: nowrap;">
                            <div style="margin: 10px; flex: 50%; text-align: center;">
                                <p>{{ Math.round(10*counter1.stats.Phase2Voltage/100)/10 }} V</p>
                            </div>
                            <div style="margin: 10px; flex: 50%; text-align: center;">
                                <p>{{ Math.round(10*counter1.stats.Phase2Current/1000)/10 }} A</p>
                            </div>
                        </div>
                        <div style="display: flex; flex-wrap: nowrap;">
                            <div style="margin: 10px; flex: 50%; text-align: center;">
                                <p>{{ Math.round(10*counter1.stats.Phase3Voltage/100)/10 }} V</p>
                            </div>
                            <div style="margin: 10px; flex: 50%; text-align: center;">
                                <p>{{ Math.round(10*counter1.stats.Phase3Current/1000)/10 }} A</p>
                            </div>
                        </div>
                        <div style="display: flex; flex-wrap: nowrap;">
                            <div style="margin: 10px; flex: 100%; text-align: center;">
                                <p>{{ Math.round(10*counter1.stats.Frequency/1000)/10 }} Hz</p>
                            </div>
                        </div>
                    </div>  
                    <div style="flex:50%; padding: 20px;">
                        <div style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <p style="font-size: 1.4rem; font-weight: bold;">{{ Math.round(10 * counter1.stats.TotalActivePower * 10) / 10 }} W</p> 
                            <p style="font-size: 0.9rem; font-weight: normal;">{{ Math.round(10 * counter1.stats.TotalActiveEnergy* 1000 / 1000) / 10 }} kWh</p> 
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 20px;">
                    <p style="font-size: 0.8rem;">{{ counter1.datetime }}</p>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 15px;">
                    <i class="chart-icon fa-solid fa-chart-line" @click="this.$router.push({ path: '/energija-diagram', query: { counter: 1 } })"></i>
                    <i v-show="false" class="warning-icon fa-solid fa-triangle-exclamation"></i>        
                </div>
                
            </div>
            <div class="test">
                <div style="padding-bottom: 10px; border-bottom: 1px solid #83E8A2; text-align: center;">
                    <p>Punionica TUC 1</p>
                </div>
                <div style="display: flex; flex-wrap:nowrap;">
                <div style="padding-right: 20px; border-right: 1px solid #83E8A2;">
                    <div style="display: flex; flex-wrap: nowrap;">
                        <div style="margin: 10px; flex: 50%; text-align: center;">
                            <p>{{ Math.round(10*counter2.stats.Phase1Voltage/100)/10 }} V</p>
                        </div>
                        <div style="margin: 10px; flex: 50%; text-align: center;">
                            <p>{{ Math.round(10*counter2.stats.Phase1Current/1000)/10 }} A</p>
                        </div>
                    </div>
                    <div style="display: flex; flex-wrap: nowrap;">
                        <div style="margin: 10px; flex: 100%; text-align: center;">
                            <p>{{ Math.round(10*counter2.stats.Frequency/100)/10 }} Hz</p>
                        </div>
                    </div>
                 </div>
                 <div style="flex:50%; padding: 20px;">
                        <div style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <p style="font-size: 1.4rem; font-weight: bold;">{{ Math.round(10 * counter2.stats.TotalActivePower * 10) / 10 }} W</p>
                            <p style="font-size: 0.9rem; font-weight: normal;">{{ Math.round(10 * counter2.stats.TotalActiveEnergy * 1000 / 1000) / 10 }} kWh</p>  
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 20px;">
                    <p style="font-size: 0.8rem;">{{ counter2.datetime }}</p>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 15px;">
                    <i class="chart-icon fa-solid fa-chart-line" @click="this.$router.push({ path: '/energija-diagram', query: { counter: 2 } })"></i>
                    <i v-show="false" class="warning-icon fa-solid fa-triangle-exclamation"></i>     
                </div>
            </div>
            <div class="test">
                <div style="padding-bottom: 10px; border-bottom: 1px solid #83E8A2; text-align: center;">
                    <p>Punionica TUC 2</p>
                </div>
                <div style="display: flex; flex-wrap:nowrap;">
                <div style="padding-right: 20px; border-right: 1px solid #83E8A2;">
                    <div style="display: flex; flex-wrap: nowrap;">
                        <div style="margin: 10px; flex: 50%; text-align: center;">
                            <p>{{ Math.round(10*counter3.stats.Phase1Voltage/100)/10 }} V</p>
                        </div>
                        <div style="margin: 10px; flex: 50%; text-align: center;">
                            <p>{{ Math.round(10*counter3.stats.Phase1Current/1000)/10 }} A</p>
                        </div>
                    </div>
                    <div style="display: flex; flex-wrap: nowrap;">
                        <div style="margin: 10px; flex: 100%; text-align: center;">
                            <p>{{ Math.round(10*counter3.stats.Frequency/100)/10 }} Hz</p>
                        </div>
                    </div>
                </div>  
                                    <div style="flex:50%; padding: 20px;">
                        <div style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <p style="font-size: 1.4rem; font-weight: bold;">{{ Math.round(10 * counter3.stats.TotalActivePower * 10) / 10 }} W</p>
                            <p style="font-size: 0.9rem; font-weight: normal;">{{ Math.round(10 * counter3.stats.TotalActiveEnergy * 1000 / 1000) / 10 }} kWh</p>  
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 20px;">
                    <p style="font-size: 0.8rem;">{{ counter3.datetime }}</p>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 15px;">
                    <i class="chart-icon fa-solid fa-chart-line" @click="this.$router.push({ path: '/energija-diagram', query: { counter: 3 } })"></i>
                    <i v-show="false" class="warning-icon fa-solid fa-triangle-exclamation"></i>        
                </div>
            </div>
    </div>
</template>

<script>
//TotalActiveEnergy
export default {
  data() {
    return {
        counter1: { timestamp: undefined, datetime: undefined, stats: {}},
        counter2: { timestamp: undefined, datetime: undefined, stats: {}},
        counter3: { timestamp: undefined, datetime: undefined, stats: {}},
    }
  },
  mounted() {
    this.$store.state.appTitle = 'mFirule/Energija'
  },
  computed: {
  },
  methods: {
    refreshStats() {
        let data = this.$store.state.liveData;
        
        try {
            this.counter1 = {
                timestamp: data['counter1']['timestamp'],
                datetime: data['counter1']['datetime'],
                stats: JSON.parse(data['counter1']['stats'])
            }
            this.counter2 = {
                timestamp: data['counter2']['timestamp'],
                datetime: data['counter2']['datetime'],
                stats: JSON.parse(data['counter2']['stats'])
            }

            this.counter3 = {
                timestamp: data['counter3']['timestamp'],
                datetime: data['counter3']['datetime'],
                stats: JSON.parse(data['counter3']['stats'])
            }

            //console.log( this.counter1);
        } catch {

        }
    }
  }, 
  watch: {
    '$store.state.liveData': {
      handler() {
        this.refreshStats();
      },
      immediate: true,
    }
  }
}

</script>

<style lang="less">

.content-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .test {
        min-height: 1px;
        padding: 25px;
        margin: 25px;
        height: fit-content;
        background: #1e385e;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%);
    }

    p {
        color: white;
        white-space: nowrap;
        margin: 0;
    }

}


.chart-icon {

    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%); 
    font-size: 1.8rem; 
    color: #8EE8A2;  
    padding: 10px; 
    margin: 10px; 
    border-radius: 50%; 
    background-color: #216e93;

}

.warning-icon {

    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 40%); 
    font-size: 1.8rem;
    color: red;  
    padding: 10px; 
    margin: 10px;
    border-radius: 50%; 
    background-color: yellow;

}


</style>