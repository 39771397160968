import { createStore } from 'vuex'
import actions from './actions.js'

export default createStore({
    state: {
        appTitle: 'mFirule',
        API: "https://api.bioenergetika.si/firule/",
        liveData: undefined,
    },
    getters: {},
    mutations: {},
    actions,
    modules: {}
})