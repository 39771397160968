import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.js';

import Highcharts from 'highcharts';

window.bootstrap = bootstrap;
window.Highcharts = Highcharts;

createApp(App).use(store).use(router).mount('#app')